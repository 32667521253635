import React, { useState } from 'react'
import axios from 'axios';
import '../styles/AddCreditsButton.css';

export default function AddCreditsModal({ closeModal, userId }) {

    const [creditAmount, setCreditAmount] = useState(50);
    const [inputError, setInputError] = useState('');

    // Handle closing the modal
    const handleCloseModal = () => {
        closeModal(); // Trigger parent dropdown logic to close
        setCreditAmount(50);
        setInputError('');
    };

    // Handle credit amount change in the modal input
    const handleCreditAmountChange = (e) => {
        const value = parseInt(e.target.value, 10);
        if (isNaN(value)) {
        setCreditAmount(0)
        setInputError('You must purchase at least 10 credits.');
        } else if (value < 10) {
        setInputError('You must purchase at least 10 credits.');
        setCreditAmount(value);
        } else {
        setInputError(''); // Clear error if valid input
        setCreditAmount(value);
        }
    };

    // Handle checkout
    const handleCheckout = async () => {
        if (creditAmount < 10) {
        setInputError('You must purchase at least 10 credits.');
        return;
        }

        try {
        const amount = creditAmount * 20; // Convert to cents (1 credit = $0.20)

        const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/stripe/create-checkout-session', {
            userId: userId, // Pass user ID
            amount: amount, // Pass the calculated amount in cents
            credits: creditAmount, // Pass the number of credits for better session details
            description: `Purchase of ${creditAmount} credits`, // Description for the product
        });

        const { url } = response.data;
        window.location.href = url; // Redirect to the Stripe Checkout page
        } catch (error) {
        console.error('Error creating checkout session:', error);
        }
    };


    const closeCreditsModal = () => {
        setCreditAmount(50)
    }
    return (
        <div className="modal-overlay" onClick={handleCloseModal}>
            <div className="modal" onClick={(e) => e.stopPropagation()}>
            <h3>Amount of Credits<br /></h3>
            <h4>(1 Image Credit = $0.20)</h4>
            <input
                type="number"
                id="new-credits"
                name="credits"
                value={creditAmount}
                onChange={handleCreditAmountChange}
                className="credit-input"
                placeholder="Enter credit amount"
            />
            {inputError && <p className="input-error">{inputError}</p>}
            <button onClick={handleCloseModal} className="close-modal-button">Cancel</button>
            <button
                onClick={handleCheckout}
                className="checkout-button"
                disabled={!!inputError || creditAmount < 10} // Disable if input error or less than 10 credits
            >
                Checkout for ${(creditAmount * 0.2).toFixed(2)}
            </button>
            </div>
        </div>
    )
}
