import React, { useState } from 'react';

import '../styles/AddCreditsButton.css';

const AddCreditsButton = ({ userId, buttonText, className, openModal, closeModal }) => {
  const [showModal, setShowModal] = useState(false);
  

  // Handle opening the modal
  const handleOpenModal = () => {
    openModal(); // Trigger parent dropdown logic to stay open
  };

  

  return (
    <>
       <button onClick={handleOpenModal} className={className}>{buttonText}</button>
    </>
  );
};

export default AddCreditsButton;
