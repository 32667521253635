import React from 'react';
import '../styles/SettingsModal.css'; // Optional CSS for settings modal
import { Link } from 'react-router-dom';

const DeleteModal = ({ closeDeleteModal, handleDeleteImage }) => {
  return (
    <div className="modal-overlay" onClick={closeDeleteModal}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <h3>Are you sure you want to delete this image?</h3>
        <p>You won't be able to restore it!</p>
        <div className="deleteModalOptions">
          <button onClick={closeDeleteModal} className="close-modal-button">Cancel</button>
          <button onClick={handleDeleteImage} className="close-modal-button delete">Delete</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;
