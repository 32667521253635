import React from 'react';
import '../styles/SettingsModal.css'; // Optional CSS for settings modal
import { Link } from 'react-router-dom';

const SettingsModal = ({ closeSettingsModal, email }) => {
  return (
    <div className="modal-overlay" onClick={closeSettingsModal}>
      <div className="modal" onClick={(e) => e.stopPropagation()}>
        <h3>Settings</h3>
        <h4>{ email }</h4>
        <Link to='/terms'>Terms of service</Link>
        <Link to='/privacy'>Privacy Policy</Link>
        <button onClick={closeSettingsModal} className="close-modal-button">Close</button>
      </div>
    </div>
  );
};

export default SettingsModal;
