import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import AddCreditsButton from './AddCreditsButton';
// import '../styles/Account.css';

const Account = ({ setSignedIn }) => {
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState(0);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Fetch user data from Supabase
  const fetchUserData = async () => {
    const { data: { session } } = await supabase.auth.getSession();

    if (session) {
      const { user } = session;
      setUser(user);

      // Fetch credits from Supabase
      const { data: creditData, error } = await supabase
        .from('credits')
        .select('credits')
        .eq('user_id', user.id)
        .single();

      if (error) {
        setError('Error fetching credits.');
      } else {
        setCredits(creditData.credits);
      }
    } else {
      setSignedIn(false);
      navigate('/signin'); // Redirect to sign-in if not authenticated
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [setSignedIn, navigate]);

  // Handle signing out
  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setSignedIn(false);
    navigate('/');
  };

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <div className="account-page">
      <h2>Account Details</h2>
      <p><strong>Email:</strong> {user.email}</p>
      <p><strong>Credits:</strong> {credits} <AddCreditsButton userId={user.id} /></p>
      {error && <p className="error-message">{error}</p>}

      <button onClick={handleSignOut} className="sign-out-button">Sign Out</button>
    </div>
  );
};

export default Account;
