import React from 'react'
import '../styles/tospp.css'

export default function Privacy() {
  return (
    <div className='pp'>
        <p>
        Privacy Policy for Teuring <br/>
        Effective Date: October 14, 2024
        <br/><br/>
        At Teuring, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your personal data when you use our website, https://teuring.com.
        <br/><br/>
        1. Information We Collect <br/>
        We collect the following personal information:
        <br/><br/>
        Name <br/>
        Email address <br/>
        Payment information <br/>
        Additionally, we collect non-personal information via web cookies to improve your experience. <br/>
        <br/><br/>
        2. Purpose of Data Collection <br/>
        The personal data we collect is used solely for processing orders on our platform.
        <br/><br/>
        3. Data Sharing <br/>
        We do not share your personal data with any third parties.
        <br/><br/>
        4. Children's Privacy <br/>
        Teuring does not knowingly collect personal data from children under the age of 13. If we become aware that a child has provided us with personal information, we will delete it immediately.
        <br/><br/>
        5. Updates to This Privacy Policy <br/>
        We may update this Privacy Policy from time to time. Any changes will be communicated via email to users.
        <br/><br/>
        6. Contact Us <br/>
        If you have any questions about this Privacy Policy, please contact us at: <br/>
        Email: team@teuring.com
        <br/><br/>
        Thank you for using Teuring.
        </p>
    </div>
  )
}
