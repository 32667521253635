import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../supabaseClient'; // Import Supabase client
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
import ImageDisplay from './ImageDisplay';
import AddCreditsButton from './AddCreditsButton';
import AccountDropdown from './AccountDropdown.js';
import GradientComponent from './MovingGradient.js'
import DeleteModal from './DeleteModal.js';
import AddCreditsModal from './AddCreditsModal.js';

const ImageGenerator = ({ signedIn, setSignedIn }) => {
  const [prompt, setPrompt] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const [library, setLibrary] = useState([]); // Store generated images with prompts
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);
  const [deletingImage, setDeletingImage] = useState(null)
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [credits, setCredits] = useState(0); // Store remaining credits
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth); // Start with the current window width
  const [isCreditsModalOpen, setIsCreditsModalOpen] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [sidebarPosition, setSidebarPosition] = useState({position: 'absolute'})


  
  const openCreditsModal = () => {
    console.log("OPEN")
    setIsCreditsModalOpen(true);
  };

  const closeCreditsModal = async () => {
    console.log("CLOSE")
    setIsCreditsModalOpen(false);
  };

  const handleCreditsInfoClick = () => {
    openCreditsModal(); // Open the modal when credits-info is clicked
  };
  
  const navigate = useNavigate();
  
  const aspectRatios = {
    '1:1': { width: 1024, height: 1024 },
    '4:3': { width: 1024, height: 768 },
    '16:9': { width: 1024, height: 576 },
  };
  
  const [selectedRatio, setSelectedRatio] = useState('1:1');
  const [dimensions, setDimensions] = useState(aspectRatios['1:1']);
  const containerRef = useRef(null); // Reference to the image container
  const [containerWidth, setContainerWidth] = useState(1024); // Default width
  
  useEffect(() => {
    // Function to update the container width dynamically
    const updateContainerWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
  
    // Initial width set
    updateContainerWidth();
  
    // Add event listener to handle window resizing
    window.addEventListener('resize', updateContainerWidth);
  
    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, []);

  useEffect(() => {
  const handleResize = () => {
    const newScreenWidth = window.innerWidth; // Capture the new window width
    // console.log('inner width:', newScreenWidth);
    // console.log('sidebar open:', sidebarOpen);
    // console.log('sw > 1040px', newScreenWidth > 1040); // Compare with the new width
    // console.log('width:', (sidebarOpen && newScreenWidth > 1040) ? '50%' : '100%');
    setScreenWidth(newScreenWidth); // Update screenWidth with the new value
    // setContainerWidth(newScreenWidth); // Update container width on resize
  };

  // Add the resize event listener
  window.addEventListener('resize', handleResize);

  // Call handleResize initially to set the correct values
  handleResize();

  // Cleanup the event listener on component unmount
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, [sidebarOpen]); // Add sidebarOpen as a dependency if it can change

  
  

  useEffect(() => {console.log('library:', library)}, [library])
  useEffect(() => {console.log('user:', user)}, [user])
  useEffect(() => {
    const checkSessionAndFetchImagesAndCredits = async () => {
      // const cachedData = localStorage.getItem('signedImages');
      // const cachedTimestamp = localStorage.getItem('signedImagesTimestamp');
      // const cachedUserId = localStorage.getItem('signedImagesUserId'); // Get cached userId
      const currentTime = Date.now();
      
      console.log("Getting session!")
      const { data: { session } } = await supabase.auth.getSession();

      console.log('session:', session)
  
      if (session) {
        const { user } = session;
        setUser(user)
        setSignedIn(true)

        // Fetch the user's credits from the 'credits' table
        const { data: creditData, error: creditError } = await supabase
          .from('credits')
          .select('credits')
          .eq('user_id', session.user.id)
          .single();

        if (creditError) {
          console.error('Error fetching credits:', creditError);
        } else {
          setCredits(creditData.credits); // Set the user's credits
        }
  
        // Check if the user has changed accounts
        // if (cachedUserId && cachedUserId !== user.id) {
        //   console.log('User changed, clearing cached data.');
        //   localStorage.removeItem('signedImages');
        //   localStorage.removeItem('signedImagesTimestamp');
        //   localStorage.removeItem('signedImagesUserId');
        // }
  
        // setUser(user);
        // setSignedIn(true);
  
        // // Use cached data if available, timestamp is valid, and userId matches
        // if (cachedData && cachedTimestamp && (currentTime - cachedTimestamp < 300 * 1000) && cachedUserId === user.id) {
        //   const parsedCachedData = JSON.parse(cachedData);
        //   setLibrary(parsedCachedData); // Use cached URLs
        //   console.log('Using cached signed URLs');
        //   return
        // }
  
        setLoading(true);  // Start loading
  
        try {
  
          // Fetch the user's image filenames from the 'images' table
          const { data: imagesData, error: imageError } = await supabase
            .from('images')
            .select('file_name, prompt, created_at')
            .eq('user_id', session.user.id)
            .order('created_at', { ascending: false });
  
          if (imageError) {
            console.error('Error fetching images:', imageError);
          } else {
            // Generate signed URLs for each image
            const signedUrlsPromises = imagesData.map(async (image) => {
              const { data: signedUrlData, error: signedUrlError } = await supabase
                .storage
                .from('generated-images')
                .createSignedUrl(image.file_name, 3600); // URL valid for 1 hour
  
              if (signedUrlError) {
                console.error('Error generating signed URL:', signedUrlError);
                return null; // If there's an error, skip this image
              }
  
              return {
                image_url: signedUrlData.signedUrl,
                fileName: image.file_name,
                prompt: image.prompt,
                created_at: image.created_at,
              };
            });
  
            // Wait for all signed URLs to be generated
            const signedImages = await Promise.all(signedUrlsPromises);
  
            // Filter out any null values (in case of errors)
            const validImages = signedImages.filter(image => image !== null);
  
            // Save to localStorage with userId
            localStorage.setItem('signedImages', JSON.stringify(validImages));
            localStorage.setItem('signedImagesTimestamp', currentTime.toString());
            localStorage.setItem('signedImagesUserId', user.id); // Save userId
  
            // Set the library with signed URLs and other image data
            setLibrary(validImages);
          }
        } catch (error) {
          console.error('Error fetching user images or credits:', error);
        } finally {
          setLoading(false); // Stop loading
        }
      } else {
        setSignedIn(false);
        setLoading(false); // Stop loading
      }
    };
  
    checkSessionAndFetchImagesAndCredits();
  }, [setSignedIn, signedIn]);
  
  const handleGenerateImage = async (promptText = prompt) => {
    console.log("Trying to generate image")
    if (!signedIn) {
      console.log("Not signed in")
      navigate('/signin');
      return;
    }
  
    if (!promptText.trim()) {
      console.log("Error 1")
      setError('Please enter a prompt.');
      return;
    }
  
    if (credits <= 0) {
      console.log("Error 2")
      setError('You have no remaining credits. Please top up your credits.');
      return;
    }
  
    setGenerating(true);
    setError('');
    setImageUrl(null);
  
    try {
      const { data: { session } } = await supabase.auth.getSession();
  
      if (!session) {
        console.error('User not authenticated');
        navigate('/signin');
        return;
      }
  
      const token = session.access_token;
  
      console.log("Asking for response from the server on:", (process.env.REACT_APP_SERVER_URL + '/images/generate'))
      const response = await axios.post(process.env.REACT_APP_SERVER_URL + '/images/generate', {
        prompt: promptText,
        width: dimensions.width,
        height: dimensions.height,
        seed: Math.floor(Date.now() / 1000), // Seed with the current timestamp
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response)
  
      const generatedImageUrl = response.data.imageUrl;
  
      // Update library state and localStorage
      const newImage = {
        image_url: generatedImageUrl,
        fileName: response.data.fileName,
        prompt: promptText,
        created_at: new Date().toISOString()
      };

      setImageUrl(newImage)
  
      // Add the new image to the library state
      setLibrary([newImage, ...library]);
  
      // Retrieve current cached images from localStorage
      const cachedData = localStorage.getItem('signedImages');
      const cachedUserId = localStorage.getItem('signedImagesUserId');
  
      if (cachedUserId && cachedUserId === user.id) {
        // Parse the cached data
        const parsedCachedData = cachedData ? JSON.parse(cachedData) : [];
  
        // Add the new image to the cached data
        const updatedCachedData = [newImage, ...parsedCachedData];
  
        // Save the updated data back to localStorage
        localStorage.setItem('signedImages', JSON.stringify(updatedCachedData));
        localStorage.setItem('signedImagesTimestamp', Date.now().toString());
      }
  
      // Deduct 1 credit
      const { error: creditUpdateError } = await supabase
        .from('credits')
        .update({ credits: credits - 1 })
        .eq('user_id', session.user.id);
  
      if (creditUpdateError) {
        console.error('Error updating credits:', creditUpdateError);
      } else {
        setCredits(credits - 1); // Update credits locally
      }
    } catch (err) {
      console.error(err);
      setError('Failed to generate image. Please try again.');
    } finally {
      setGenerating(false);
    }
  };

  const handleAspectRatioChange = (e) => {
    const ratio = e.target.value;
    setSelectedRatio(ratio);
    setDimensions(aspectRatios[ratio]);
    setImageUrl(null); // Reset the image URL when the aspect ratio changes
  };

  const calculateHeight = (width, ratio) => {
    const [aspectWidth, aspectHeight] = ratio.split(':').map(Number);
    return (width / aspectWidth) * aspectHeight;
  };

  const openFullscreen = (image) => {
    setFullscreenImage(image);
  };

  const closeFullscreen = () => {
    setFullscreenImage(null);
  };

  const closeDeleteModal = () => {
    setDeletingImage(null)
  }

  const handleDeleteImage = (fileName) => {
    console.log("DELETING IMAGE!", fileName)
    setDeletingImage(fileName)
  }

  const handleDeleteImageConfimation = async () => {
    setDeletingImage(null)
    try {
      // Get the current session to retrieve the user ID
      const { data: { session } } = await supabase.auth.getSession();
  
      if (!session) {
        console.error('User not authenticated');
        return;
      }
  
      // Delete the image from the 'images' table in Supabase
      const { error: deleteError } = await supabase
        .from('images')
        .delete()
        .eq('file_name', deletingImage)
        .eq('user_id', session.user.id);
  
      if (deleteError) {
        console.error('Error deleting image from Supabase:', deleteError);
        return;
      }

      if (imageUrl?.fileName === deletingImage) {
        setImageUrl(null)
      }

      if (fullscreenImage?.fileName === deletingImage) {
        setFullscreenImage(null)
      }
  
      // Remove the image from the local library state
      setLibrary(library.filter(img => img.fileName !== deletingImage));
  
      console.log('Image deleted successfully:', deletingImage);
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const downloadImage = async (imageUrl, prompt) => {
    console.log('Starting download process for:', fullscreenImage?.fileName, imageUrl, prompt);
    setIsDownloading(true)

    try {
      const response = await fetch(`/images/download-image?filename=${fullscreenImage?.fileName}`, {
        method: 'GET',
      });

      if (!response.ok) {
        console.error('Failed to download image:', response.statusText);
        throw new Error('Failed to download image');
      }

      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'downloaded_image.jpg';

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log('Image download initiated successfully.');

    } catch (error) {
      console.error('Error during image download process:', error);
    }
    setIsDownloading(false)
    setIsDownloaded(true)
  };

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    setSignedIn(false);
    setSidebarOpen(true)
    setLibrary([])
    setUser(null)
    setCredits(0)
    setFullscreenImage(null)
    setImageUrl(null)
    setPrompt('')
    navigate('/');
  };

  const handleToggleLibrary = async () => {
    
    console.log('sidebarOpen:', sidebarOpen)
    if (sidebarOpen) {
      // If opening the sidebar, set to fixed immediately
      setSidebarPosition({position: 'fixed', right: '37px'});
      console.log('Setting to fixed')
      setSidebarOpen(false);
    } else {
      // If closing the sidebar, delay the change to absolute by 0.6s
      setSidebarOpen(true);
      setTimeout(() => {
        console.log('Setting to absolute')
        setSidebarPosition({position: 'absolute'});
      }, 700); // 0.6 seconds delay
    }
  };

  return (
    
    <div className="image-generator-container" style={{width: (sidebarOpen && screenWidth > 1040) ? '100vw' : '150vw'}} >
        {isCreditsModalOpen && (
        <AddCreditsModal closeModal={closeCreditsModal} userId={user.id}/>
      )}
        {GradientComponent()}
        {/* Fullscreen Modal */}
        {fullscreenImage && (
          <div className="fullscreen-modal" onClick={closeFullscreen}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <img src={fullscreenImage.image_url} alt="Fullscreen" className="fullscreen-image" />
              <button className="close-modal" onClick={closeFullscreen}>
                <img src="/close.svg" width={40} alt="" />
              </button>
              <button className="download-button" onClick={() => downloadImage(fullscreenImage.image_url, fullscreenImage.prompt)}>
                {
                  isDownloading ? (
                    <img src="/spinner.svg" className='spinner' width={40} alt='loading icon' />
                  ) : isDownloaded ? (
                    <img src="/tick.svg" width={40} alt='downloaded icon' />
                  ) : (
                    <img src="/download.svg" width={40} alt="download icon" />
                  )
                }
              </button>
              <button className="delete-button" onClick={() => handleDeleteImage(fullscreenImage.fileName)}>
                <img src="/delete.svg" width={40} alt="download icon" />
              </button>
            </div>
          </div>
        )}

        {
          deletingImage && (
            <DeleteModal closeDeleteModal={closeDeleteModal} handleDeleteImage={handleDeleteImageConfimation} />
          )
        }

        {/* <div className={ sidebarOpen ? "account-container thin" : "account-container thin closed"}>
            {(screenWidth > 1040 && signedIn) && (
              <button onClick={handleToggleLibrary} className='sidebar-button'>
                <img src="/sidebar.svg" alt="sidebar icon" />
              </button>
            )}
            {signedIn ? (
              <AccountDropdown user={user} handleSignOut={handleSignOut} openCreditsModal={openCreditsModal} closeCreditsModal={closeCreditsModal}/>
            ) : (
              <>
                <button className="sign-in-button" onClick={() => navigate('/signin')}>
                  Sign In
                </button>
                <button className="sign-in-button" onClick={() => navigate('/signup')}>
                  Sign Up
                </button>
              </>
            )}
      </div> */}

        {/* Left Panel */}
        <div className="left-panel" style={{width: (sidebarOpen && screenWidth > 1040) ? '50%' : '100vw', maxWidth: (sidebarOpen && screenWidth > 1040) ? '50%' : '100vw'}}>
          <div className="logoContainer">
            <img src="/Teuring-Logo.png" alt="Teuring Logo" className='logo' />
          </div>
          <div className="img-and-inputs">
          <div
              ref={containerRef} // Attach ref to the container div
              style={imageUrl ?
                { backgroundColor: 'transparent', backdropFilter: 'none' }
                : {
                    width: '100%',
                    height: `${calculateHeight(containerWidth, selectedRatio)}px`,
                  }
              }
              className="image-container-main"
            >
              {/* Show the image when it loads */}
              {imageUrl ? (
                <ImageDisplay
                  imageUrl={imageUrl.image_url}
                  prompt={imageUrl.prompt}
                  onFullscreen={openFullscreen}
                  onDownload={() => {downloadImage(imageUrl.image_url, imageUrl.prompt)}}
                  onDelete={() => {handleDeleteImage(imageUrl.fileName)}}
                  image={imageUrl}
                />
              ) : generating ? (
                <span className="loader"></span>
              ) : (
                <div className="image-placeholder">
                  <img src="/camera.svg" alt="camera icon" />
                </div>
              )}
            </div>


            {/* Display remaining credits */}
            { signedIn &&
              <div className="credits-info" onClick={handleCreditsInfoClick}>
                <p><strong className='creditCount'>{credits}</strong> Credits Remaining</p>
              </div>
            }
              {
                error && (
                  <div className="errorMsg">
                    {error}
                  </div>
                )
              }
            {/* Input Bar */}
            <div className="input-bar">
              <div className="input-container">
              <input
                type="text"
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                placeholder="Enter your image prompt..."
                className="input"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleGenerateImage();
                  }
                }}
              />

                <select value={selectedRatio} onChange={handleAspectRatioChange} className="select">
                  {Object.keys(aspectRatios).map((ratio) => (
                    <option key={ratio} value={ratio}>
                      {ratio}
                    </option>
                  ))}
                </select>
              </div>
              <button onClick={() => handleGenerateImage()} className="submit-button" disabled={loading}>
                {/* {loading ? 'Generating...' : 'Generate'} */}
                <img src="/arrow-up.svg" alt="up arrow" width={40} />
              </button>
            </div>
          </div>

          {/* Suggestion Prompts */}
          <div className="suggestions">
            <div className="suggestions-container">
              {['Mountainous Film Photo', 'Friends taking a selfie', 'A bustling Tokyo street'].map((suggestion, index) => (
                <button
                  key={index}
                  className="suggestion-button"
                  onClick={() => handleGenerateImage(suggestion)}
                >
                  {suggestion}
                </button>
              ))}
            </div>
          </div>
      </div>

      {/* Right Panel: Image Library */}

      <div className="right-panel">

        <div className="account-container wide" style={ sidebarPosition }>
          {/* Sidebar Button */}
          {(screenWidth > 1040 && signedIn) && (
            <button onClick={handleToggleLibrary} className='sidebar-button'>
              <img src="/sidebar-open.svg" alt="sidebar icon" />
            </button>
          )}
            {signedIn ? (
              <AccountDropdown user={user} handleSignOut={handleSignOut} openCreditsModal={openCreditsModal} closeCreditsModal={closeCreditsModal} />
            ) : (
              <>
                <button className="sign-in-button" onClick={() => navigate('/signin')}>
                  Sign In
                </button>
                <button className="sign-in-button" onClick={() => navigate('/signup')}>
                  Sign Up
                </button>
              </>
            )}
        </div>
        <h3>Your Library</h3>
        <div className="image-columns">
          <div className="image-column column1">
            {
              loading ? (
                // Render ghost elements while loading
                Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} className="image-grid-item ghost-placeholder"></div>
                ))
              ) : (
              library
                .filter((_, index) => index % 2 === 0) // Images for the first column (even-indexed)
                .map((img, index) => (
                  <div key={index} className="image-grid-item" title={img.prompt}>
                    <ImageDisplay
                      imageUrl={img.image_url}
                      prompt={img.prompt}
                      onFullscreen={() => openFullscreen(img)}
                      onDownload={() => downloadImage(img.image_url, img.prompt)}
                      onDelete={handleDeleteImage}
                      image={img}
                      />
                  </div>
                )))
              }
          </div>

          <div className="image-column column2">
            {
              loading ? (
                // Render ghost elements while loading
                Array.from({ length: 5 }).map((_, index) => (
                  <div key={index} className="image-grid-item ghost-placeholder"></div>
                ))
              ) : (
              library
              .filter((_, index) => index % 2 !== 0) // Images for the second column (odd-indexed)
              .map((img, index) => (
                <div key={index} className="image-grid-item" title={img.prompt}>
                    <ImageDisplay
                      imageUrl={img.image_url}
                      prompt={img.prompt}
                      onFullscreen={() => openFullscreen(img)}
                      onDownload={() => downloadImage(img.image_url, img.prompt)}
                      onDelete={handleDeleteImage}
                      image={img}
                    />
                  </div>
                )))
            }
          </div>
      </div>
        {!signedIn && (
          <div className='policies'>
            <Link to='/privacy'>privacy</Link>
            <Link to='/terms'>terms</Link>
          </div>
        )}
      </div>
        
    </div>
  );
};

export default ImageGenerator;
