import React, { useEffect, useState } from 'react';

const ImageDisplay = ({ imageUrl, onFullscreen, prompt, onDelete, image }) => {

  const [isDownloading, setIsDownloading] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)


  useEffect(() => {
    // console.log("IMAGE LOG:", image)
  }, [image])

  const handleDelete = async (e, image) => {
    e.stopPropagation();
    // console.log("IMAGE DELETE LOG:", image)
    onDelete(image?.fileName)
  }

  const handleDownload = async (e) => {
    e.stopPropagation();
    console.log('Starting download process for:', image?.fileName, imageUrl, prompt);
    setIsDownloading(true)

    try {
      const response = await fetch(`/images/download-image?filename=${image?.fileName}`, {
        method: 'GET',
      });

      if (!response.ok) {
        console.error('Failed to download image:', response.statusText);
        throw new Error('Failed to download image');
      }

      const contentDisposition = response.headers.get('Content-Disposition');
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'downloaded_image.jpg';

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      console.log('Image download initiated successfully.');

    } catch (error) {
      console.error('Error during image download process:', error);
    }
    setIsDownloading(false)
    setIsDownloaded(true)
  };


  return (
    <div className="image-wrapper" onClick={() => onFullscreen(image)}>
      <img src={imageUrl} alt="Generated" className="image-main" />
      <button
        className="delete-button"
        onClick={(e) => {handleDelete(e, image)}} // Call the delete function
      >
        <img src="/delete.svg" width={40} alt="download icon" />
      </button>
      <button
        className="download-button"
        onClick={handleDownload} // Call the download function
      >
        {
          isDownloading ? (
            <img src="/spinner.svg" className='spinner' width={40} alt='loading icon' />
          ) : isDownloaded ? (
            <img src="/tick.svg" width={40} alt='downloaded icon' />
          ) : (
            <img src="/download.svg" width={40} alt="download icon" />
          )
        }
      </button>
      <button
        className="fullscreen-button"
        onClick={() => onFullscreen(image)}
      >
        <img src="/fullscreen.svg" width={40} alt="fullscreen icon" />
      </button>
    </div>
  );
};

export default ImageDisplay;
