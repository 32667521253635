import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import GradientComponent from './MovingGradient';

const SignIn = ({ setSignedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignIn = async (e) => {
    e.preventDefault();

    try {
      const { error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) {
        setError(error.message);
      } else {
        setSignedIn(true);
        navigate('/'); // Redirect to homepage after successful login
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });
      if (error) {
        setError(error.message);
      } else {
        // Redirect the user to Google for authentication
        window.location.href = data.url;
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="sign-in-wrapper">
      <div className="sign-in-container">
        <h2>Sign In</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSignIn}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="sign-in-input"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="sign-in-input"
          />
          <button type="submit" className="sign-in-button">Sign In</button>
          
          <button type="button" onClick={handleGoogleSignIn} className="google-sign-in-button">
            <img src="https://hackaday.com/wp-content/uploads/2016/08/google-g-logo.png" alt="Google logo" />
            Sign in with Google
          </button>
        </form>



        <p>Don't have an account? <Link to="/signup">Sign Up</Link></p>

        <div className="policies">
          <Link>terms</Link>
          <Link>privacy</Link>
        </div>
      </div>
      <GradientComponent />
      <Link to="/">
        <img src="/Teuring-Logo.png" className='teuring-logo' alt="Teuring Logo" />
      </Link>

    </div>
  );
};

export default SignIn;
