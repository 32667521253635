// src/App.js

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ImageGenerator from './components/ImageGenerator';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Account from './components/Account';
import EmailVerification from './components/EmailVerification'; // Import the new component
import Tos from './components/Tos';
import Privacy from './components/Privacy';
import { useState } from 'react';
import './styles/AuthButtons.css';
import './styles/CreditsInfo.css';
import './styles/ImageDisplay.css';
import './styles/ImageLibrary.css';
import './styles/InputBar.css';
import './styles/Layout.css';
import './styles/Suggestions.css';
import './styles/SignIn.css';
import './styles/GradientBackground.css';
import './styles/Loading.css';

function App() {
  const [signedIn, setSignedIn] = useState(false);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<ImageGenerator signedIn={signedIn} setSignedIn={setSignedIn} />} />
        <Route path="/signin" element={<SignIn setSignedIn={setSignedIn} />} />
        <Route path="/signup" element={<SignUp setSignedIn={setSignedIn} />} />
        <Route path="/account" element={<Account setSignedIn={setSignedIn} />} />
        <Route path="/email-verification" element={<EmailVerification />} />
        <Route path="/terms" element={<Tos />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
}

export default App;
