// src/components/EmailVerification.js

import React from 'react';
import { Link } from 'react-router-dom';
import GradientComponent from './MovingGradient';
import '../styles/EmailVerification.css'; // You will create this CSS file

const EmailVerification = () => {
  return (
    <div className="verification-container">
      <div className="verification-message">
        <h2>Check your email to verify your account</h2>
        <img src="/email.svg" alt="Email Icon" className="email-icon" />
        <p>
          We've sent an email to your inbox. Please click the verification link to complete your sign-up process.
        </p>
        <Link to="/signup">
          <button className="return-button">Back to Sign Up</button>
        </Link>
      </div>
      <GradientComponent />
    </div>
  );
};

export default EmailVerification;
