import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useNavigate, Link } from 'react-router-dom';
import GradientComponent from './MovingGradient';

const SignUp = ({ setSignedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();

    try {
      // Sign up the user, no need to create credits now
      const { error } = await supabase.auth.signUp({ email, password });

      if (error) {
        setError(error.message);
      } else {
        // Redirect to email verification page
        navigate('/email-verification');
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  const handleGoogleSignUp = async () => {
    try {
      const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
      });

      if (error) {
        setError(error.message);
      } else {
        // Redirect the user to Google for authentication
        window.location.href = data.url;
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="sign-in-wrapper">
      <div className="sign-in-container">
        <h2>Sign Up</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleSignUp}>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="sign-up-input"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="sign-up-input"
          />
          <button type="submit" className="sign-in-button">Sign Up</button>
          <button type="button" onClick={handleGoogleSignUp} className="google-sign-in-button">
            <img src="https://hackaday.com/wp-content/uploads/2016/08/google-g-logo.png" alt="Google logo" />
            Sign up with Google
          </button>
        </form>



        <p>Already have an account? <Link to="/signin">Sign In</Link></p>

        <div className="policies">
          <Link>terms</Link>
          <Link>privacy</Link>
        </div>
      </div>
      <Link to="/">
        <img src="/Teuring-Logo.png" className='teuring-logo' alt="Teuring Logo" />
      </Link>
      <GradientComponent />
    </div>
  );
};

export default SignUp;
