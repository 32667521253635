import React, { useState, useEffect } from 'react';
import AddCreditsButton from './AddCreditsButton'; 
import SettingsModal from './SettingsModal'; // New component for settings modal

const AccountDropdown = ({ user, handleSignOut, openCreditsModal, closeCreditsModal }) => { // Add openCreditsModal prop
  const [showDropdown, setShowDropdown] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);

  useEffect(() => {
    console.log("ModalOpen:", isModalOpen, "SettingsModalOpen:", isSettingsModalOpen)
    console.log(user)
  }, [isModalOpen, isSettingsModalOpen]);

  const handleMouseEnter = () => {
    if (!isModalOpen && !isSettingsModalOpen) {
      setShowDropdown(true);
    }
  };

  const handleMouseLeave = () => {
    if (!isModalOpen && !isSettingsModalOpen) {
      setShowDropdown(false);
    }
  };

  const openSettingsModal = () => {
    setIsSettingsModalOpen(true);
    setShowDropdown(true);
  };

  const closeSettingsModal = () => {
    setIsSettingsModalOpen(false);
    setShowDropdown(false);
  };

  return (
    <div
      className="account-dropdown-container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="account-circle">
        <img src="/account.svg" alt="account icon" />
      </div>
      {!isModalOpen && !isSettingsModalOpen && showDropdown && 
        <p className='email'>{user?.email}</p>
      }
      {showDropdown && (
        <div className="account-dropdown">
          {(isModalOpen || isSettingsModalOpen) && 
            <p className='email'>{user?.email}</p>
          }
          <button className="settings" onClick={openSettingsModal}>
            <img src="/settings.svg" alt="" />
            Settings
          </button>
          <AddCreditsButton
            buttonText={<><img src="/coins.svg" alt="" />Buy credits</>}
            className={'addTokens'}
            userId={user?.id}
            openModal={openCreditsModal} // Use the prop here
            closeModal={closeCreditsModal}
          />
          <button className="sign-out-button" onClick={handleSignOut}>
            <img src="/logout.svg" alt="" />
            Log out
          </button>
        </div>
      )}
      {isSettingsModalOpen && (
        <SettingsModal closeSettingsModal={closeSettingsModal} email={user?.email}/>
      )}
    </div>
  );
};

export default AccountDropdown;
