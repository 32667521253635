import { useEffect } from 'react';
import { Gradient } from './gradient.js'


const GradientComponent = () => {
  useEffect(() => {
    const gradient = new Gradient();
    gradient.initGradient('#gradient-canvas');
  }, []);

  return <canvas id="gradient-canvas" data-transition-in />;
};

export default GradientComponent;