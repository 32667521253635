import React from 'react'

import '../styles/tospp.css'

export default function Tos() {
  return (
    <div className='tos'>
        <p>
        Teuring Terms of Service <br/>
        Effective Date: October 14, 2024
        <br />
        <br />
        Welcome to Teuring! By accessing or using our website (https://teuring.com), you agree to comply with and be bound by the following Terms of Service.
        <br />
        <br />
        1. Services <br/>
        Teuring offers an AI image generation service that adjusts user prompts to produce enhanced results. Users can purchase credits to generate images through our platform.
        <br />
        <br />
        2. Ownership of Outputs <br/>
        Teuring uses the Black Forest Labs API to generate images. As per their Terms of Service, Teuring claims no ownership rights over the images ("Outputs"). Users may use the images for personal or commercial purposes.
        <br />
        <br />
        3. User Accounts <br/>
        To access our services, users must create an account by providing their name, email address, and payment information. By creating an account, you agree to provide accurate and current information.
        <br />
        <br />
        4. Payment <br/>
        Users purchase image credits to generate images. Payment information is securely processed, and users are responsible for maintaining sufficient credits in their account to use the service.
        <br />
        <br />
        5. Data Collection <br/>
        Teuring collects personal data, including name, email, and payment information, as well as non-personal data through web cookies. For more details, please review our Privacy Policy at <a href="https://teuring.com/privacy">teuring.com/privacy</a>.
        <br />
        <br />
        6. Use of Website <br/>
        By using Teuring, you agree not to misuse the service, including but not limited to:
        <br />
        Attempting to access systems or data you are not authorized to access.
        <br />
        Engaging in fraudulent activity or violating any applicable laws.
        <br />
        <br />
        7. Updates to the Terms <br/>
        Teuring reserves the right to update these Terms of Service. Users will be notified of any updates via email.
        <br />
        <br />
        8. Governing Law <br/>
        These Terms of Service are governed by and construed in accordance with the laws of Australia.
        <br />
        <br />
        9. Contact Information <br/>
        For any questions regarding these Terms, please contact us at team@teuring.com.
        </p>
    </div>
  )
}
